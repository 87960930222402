














































































































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { BonusService } from '@/services/bonus-service';
import bonuseUpload from './compoennts/bonuseUpload.vue';
import { getMoney } from '@/services/utils';
@Component({
  components: {
    bonuseUpload
  }
})
export default class wechatImageText extends Vue {
  public form: any = {
    size: 10,
    current: 1,
    total: 0
  };
  private tableData: any[] = [];
  public franchise: any[] = [];
  public loading: Boolean = true;
  public kindshow: Boolean = false;
  private currentRow: any = null;

  @Inject(BonusService) private bonusService!: BonusService;

  public async created(): Promise<void> {
    await this.getList();
    this.franchiseList();
  }

  public search() {
    this.form.current = 1;
    if(this.form.franchise){
      this.form.franchiseParam = this.form.franchise.replace(new RegExp("\\&","g"),"%26")
    }
    this.getList()
  }
  public clearEvent(){
    this.form.franchiseParam = ''
  }
   //franchise
  public async franchiseList(): Promise<void> {
    const res = await this.bonusService.franchise();
    this.franchise = res
  }
  //分页相关
  public handleSizeChange(size: number) {
    this.form.size = size;
    this.getList();
  }
  public handleCurrentChange(current: number) {
    this.form.current = current;
    this.getList();
  }
  //获取数据源
  public async getList() {
    this.loading = true;
    const res = await this.bonusService.stgTargetList(this.form);
    this.loading = false;
    // let data: any = res.records
    let data: any = res.records.map((element:any) => {
      if (element.fstTargetMoney) {
        element.fstTargetMoney = '' + getMoney(element.fstTargetMoney)
        element.sndTargetMoney = '' + getMoney(element.sndTargetMoney)
        element.trdTargetMoney = '' + getMoney(element.trdTargetMoney)
        element.lastFstMoney = '' + getMoney(element.lastFstMoney)
        element.lastSndMoney = '' + getMoney(element.lastSndMoney)
        element.lastTrdMoney = '' + getMoney(element.lastTrdMoney)
        element.fstEstimateMoney = '' + getMoney(element.fstEstimateMoney)
        element.sndEstimateMoney = '' + getMoney(element.sndEstimateMoney)
        element.trdEstimateMoney = '' + getMoney(element.trdEstimateMoney)
      }
      return element;
    });
    this.tableData = data
    this.form.total = res.total;
    this.form.size = res.size;
    this.form.current = res.current;
  }
    //下载
    public async downLoadAll() {
    this.loading = true;
    var data_ = {
      type:'6'
    }
    const res = await this.bonusService.downLoadAll(data_,'指标&同期销售.xlsx');
    this.loading = false;
  }
  // 重置
  public reset() {
    this.form = {
      size: 10,
      current: 1,
      total: 0
    };
    this.search()
  }

}
